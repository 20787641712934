import { AnyAction } from '@reduxjs/toolkit';
import { IConfiguredProductData } from 'pages/ConfigurePage/ConfigureForm/hooks';
import { Dispatch } from 'react';
import { setExtraPriceQuestionData } from 'store/Model/actions/model';
import { setSelectedExtraPriceQuestionOption } from 'store/SubmitConfiguration/actions/submitConfiguration';
import {
  IExtraPriceQuestionData,
  IExtraPriceQuestionOption,
} from 'types/Configuration.types';
import { IQuestionDifferences } from './validation';

export const handleExtraPriceQuestionAfterSuccessfulQuery = (
  dispatch: Dispatch<AnyAction>,
  selectedExtraPriceQuestionOption: IExtraPriceQuestionOption | null,
  extra_price_question_data: IExtraPriceQuestionData | null
) => {
  if (!extra_price_question_data) {
    dispatch(setExtraPriceQuestionData(null));
    dispatch(setSelectedExtraPriceQuestionOption(null));
  } else {
    if (!selectedExtraPriceQuestionOption) {
      // Find and set the default option only if an option hasnt been selected yet
      const defaultOption: IExtraPriceQuestionOption | undefined =
        extra_price_question_data.options.find(
          (option: IExtraPriceQuestionOption) => option.is_default
        );
      dispatch(setExtraPriceQuestionData(extra_price_question_data));
      if (defaultOption) {
        dispatch(setSelectedExtraPriceQuestionOption(defaultOption));
      }
    }
  }
};

export const handleExtraPriceQuestionAfterObtainingEditConfiguration = (
  dispatch: Dispatch<AnyAction>,
  extraPriceQuestionData: IExtraPriceQuestionData | null, // Obtained from query inside of checkDifferences
  editConfigurationData: IConfiguredProductData
): IQuestionDifferences => {
  const differences: IQuestionDifferences = {
    missingQuestionsKeys: [],
    extraQuestionsKeys: [],
    matchingQuestionsWithInvalidOutputsKeys: [],
  };
  if (!extraPriceQuestionData) {
    // Extra price has been turned off, show differences if it exists in obtained edit configuration
    if (
      editConfigurationData.extraPriceQuestionName &&
      editConfigurationData.selectedExtraPriceQuestionOption
    ) {
      differences.missingQuestionsKeys.push(
        editConfigurationData.extraPriceQuestionName
      );
    }
    return differences;
  }
  // If it is still on, set the data
  dispatch(setExtraPriceQuestionData(extraPriceQuestionData));
  // Check if option is still a valid option, if not show EditConfigurationValidationWarningsModal
  if (
    editConfigurationData.extraPriceQuestionName &&
    editConfigurationData.selectedExtraPriceQuestionOption
  ) {
    for (const option of extraPriceQuestionData.options) {
      if (
        option.label ===
          editConfigurationData.selectedExtraPriceQuestionOption.label &&
        option.value ===
          editConfigurationData.selectedExtraPriceQuestionOption.value
      ) {
        dispatch(
          setSelectedExtraPriceQuestionOption(
            editConfigurationData.selectedExtraPriceQuestionOption
          )
        );
        // Option found, return no differences
        return {
          missingQuestionsKeys: [],
          extraQuestionsKeys: [],
          matchingQuestionsWithInvalidOutputsKeys: [],
        };
      }
    }
    // Option not found, append to differences and return
    differences.matchingQuestionsWithInvalidOutputsKeys.push(
      editConfigurationData.extraPriceQuestionName
    );
  }

  return differences;
};

export const mergeDifferences = (
  differences: IQuestionDifferences,
  extraPriceQuestionDifferences: IQuestionDifferences
) => {
  differences.missingQuestionsKeys = [
    ...differences.missingQuestionsKeys,
    ...extraPriceQuestionDifferences.missingQuestionsKeys,
  ];
  differences.extraQuestionsKeys = [
    ...differences.extraQuestionsKeys,
    ...extraPriceQuestionDifferences.extraQuestionsKeys,
  ];
  differences.matchingQuestionsWithInvalidOutputsKeys = [
    ...differences.matchingQuestionsWithInvalidOutputsKeys,
    ...extraPriceQuestionDifferences.matchingQuestionsWithInvalidOutputsKeys,
  ];
  return differences;
};
