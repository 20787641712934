import { COLORS, marginLg, paddingSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';
import { HAS_IMAGES_BREAKPOINT } from '../hooks';
import { hoverLineOut } from 'assets/styled/commonStyles';

interface StyledProps {
  hasValue: boolean;
  showBorder: boolean;
  hasPrice: boolean;
  hasImages: boolean;
  opacityIndex: number;
  opacityCoeficient: number;
  hasRedStar?: boolean;
  isSkeleton?: boolean;
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 50%;
  width: 100%;
  height: 100%;

  ${(props: StyledProps) =>
    !props.hasImages &&
    css`
      width: 100%;
      max-width: 100%;
    `};

  @media screen and (max-width: ${HAS_IMAGES_BREAKPOINT}px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const FormContainer = styled.div``;

export const ClearAllFieldsLabel = styled.label`
  position: relative;
  font-size: 17rem;
  font-weight: 400;
  color: ${COLORS.RED_100};
  margin-right: ${paddingSm};
  cursor: pointer;
  user-select: none;
  z-index: 8;
  ${hoverLineOut};
  ${respondTo.bigPhone`
    font-size: 16rem;
  `};
`;

export const LabelsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const ModelNotFoundLabel = styled.label`
  text-align: center;
  width: 100%;
  font-size: 24rem;
  line-height: 32rem;
  font-weight: 400;
  margin-bottom: ${marginLg};
`;
