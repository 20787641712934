export const SET_MODEL = 'SET_MODEL';
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const SET_QUERY_PAYLOAD = 'SET_QUERY_PAYLOAD;';
export const SET_IMAGES = 'SET_IMAGES';
export const SET_PRICING = 'SET_PRICING';
export const SET_LANG = 'SET_LANG'; // The language that was selected when the query was initiated
export const SET_IS_USING_SUPPLIER_SYSTEM_DISCOUNTS =
  'SET_IS_USING_SUPPLIER_SYSTEM_DISCOUNTS';

export const QUERY_ATTEMPT = 'QUERY_ATTEMPT';
export const QUERY_SUCCESS = 'QUERY_SUCCESS';
export const QUERY_ERROR = 'QUERY_ERROR';
export const QUERY_RESET = 'QUERY_RESET';

export const RESET_CONFIGURE_FORM = 'RESET_CONFIGURE_FORM';

export const SET_MODELS = 'SET_MODELS';

// Form
export const SET_IS_FORM_VALID = 'SET_IS_FORM_VALID';
export const SET_ARE_ALL_FIELDS_TOUCHED = 'SET_ARE_ALL_FIELDS_TOUCHED';
export const SET_TOUCHED_QUESTION_NAME = 'SET_TOUCHED_QUESTION_NAME';
export const RESET_TOUCHED_QUESTION_NAMES = 'RESET_TOUCHED_QUESTION_NAMES';
export const SET_MODEL_NOT_FOUND = 'SET_MODEL_NOT_FOUND';
export const SET_TRIGGER_QUERY_REQUEST_AFTER_FORM_RESET =
  'SET_TRIGGER_QUERY_REQUEST_AFTER_FORM_RESET';

// Common
export const SET_EDIT_CONFIGURATION_VALIDATION_WARNINGS =
  'SET_EDIT_CONFIGURATION_VALIDATION_WARNINGS';
export const RESET_EDIT_CONFIGURATION_VALIDATION_WARNINGS =
  'RESET_EDIT_CONFIGURATION_VALIDATION_WARNINGS';
export const SET_IS_DARK_MODE = 'SET_IS_DARK_MODE';
export const SET_APP_MODE = 'SET_APP_MODE';
export const SET_QUERY_PARAMS_PROCESSED = 'SET_QUERY_PARAMS_PROCESSED'; // Delay private/public route redirects until query params have been processed
export const SET_IS_MOBILE_APP = 'SET_IS_MOBILE_APP';
export const SET_IS_LOADING_SCREEN_SHOWN = 'SET_IS_LOADING_SCREEN_SHOWN';

// Shopping cart
export const TRIGGER_ADD_TO_SHOPPING_CART = 'TRIGGER_ADD_TO_SHOPPING_CART';
export const SET_TRIGGER_SHOW_ADDED_TO_CART_MODAL =
  'SET_TRIGGER_SHOW_ADDED_TO_CART_MODAL';
export const SET_CURRENTLY_EDITING_SHOPPING_CART_LINE_ID =
  'SET_CURRENTLY_EDITING_SHOPPING_CART_LINE_ID';
export const SET_TRIGGER_SHOW_CART_ITEM_EDITED_MODAL =
  'SET_TRIGGER_SHOW_CART_ITEM_EDITED_MODAL';
export const TRIGGER_EDIT_CART_ITEM = 'TRIGGER_EDIT_CART_ITEM';

// Submit configuration
export const TRIGGER_SUBMIT_CONFIGURATION = 'TRIGGER_SUBMIT_CONFIGURATION';
export const SET_INTENT_UUID = 'SET_INTENT_UUID';
export const GET_EDIT_CONFIGURATION_ATTEMPT = 'GET_EDIT_CONFIGURATION_ATTEMPT';
export const GET_EDIT_CONFIGURATION_SUCCESS = ' GET_EDIT_CONFIGURATION_SUCCESS';
export const GET_EDIT_CONFIGURATION_ERROR = 'GET_EDIT_CONFIGURATION_ERROR';
export const GET_EDIT_CONFIGURATION_RESET = 'GET_EDIT_CONFIGURATION_RESET';
export const SET_TEST_COMPANY_MODEL_CONFIGURATION =
  'SET_TEST_COMPANY_MODEL_CONFIGURATION';
export const SET_BASE64_METADATA = 'SET_BASE64_METADATA';
export const SET_IS_TEST_CONFIGURATION_MODAL_OPEN =
  'SET_IS_TEST_CONFIGURATION_MODAL_OPEN';
export const SET_EXTRA_PRICE_QUESTION_DATA = 'SET_EXTRA_PRICE_QUESTION_DATA';
export const SET_SELECTED_EXTRA_PRICE_QUESTION_OPTION =
  'SET_SELECTED_EXTRA_PRICE_QUESTION_OPTION';

// User
export const SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_SUPPLIER = 'SET_SUPPLIER';
export const LOGOUT = 'LOGOUT';
export const SET_UPDATED_USER_INFO = 'SET_UPDATED_USER_INFO';
export const SET_INTENT = 'SET_INTENT';
