import {
  Container,
  LeftSide,
  NavigationLabel,
  RightSide,
  RowSpaceBetween,
  SwitchersWrapper,
} from './Header.styled';
import { ReactComponent as LogyxLogoSVG } from 'assets/icons/logyxLogo.svg';
import { useLocation } from 'react-router-dom';
import { RoutesConfig } from 'navigation/routes';
import SubHeader from 'components/SubHeader/SubHeader';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useRef, useState } from 'react';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import ProfileDropdown from './ProfileDropdown/ProfileDropdown';
import { getModelIdFromPath } from 'providers/QueryProvider/hooks';
import { useNavigateWithQuery } from 'utils/hooks/useNavigateWithQuery';
import ToggleDarkMode from 'components/ToggleDarkMode/ToggleDarkMode';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { AppMode } from 'store/Common/reducers/common';
import SwitchAppMode from 'components/SwitchAppMode/SwitchAppMode';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import SideMenu from './SideMenu/SideMenu';
import { getLogoNavigationDestinationBasedOnAppMode } from './helpers';
import {
  List,
  GearSix as SettingsIcon,
  ShoppingCart,
  User,
} from '@phosphor-icons/react';
import Tooltip from 'components/Tooltip/Tooltip';
import EnvironmentBanner from 'components/EnvironmentBanner/EnvironmentBanner';
import useHasRole from 'utils/hooks/useHasRole';
import { PermissionGroupSupplierId } from 'types/Permissions.types';

const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const modelId = getModelIdFromPath();
  const { isSmallPhone, isBigTablet, isTablet } = useBreakpointFlag();

  const [sideMenuIsOpen, setSideMenuIsOpen] = useState<boolean>(false);

  const userId = useSelector((state: IRootReducerState) => state.userInfo.id);
  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );

  const navigateWithQuery = useNavigateWithQuery();

  const [showProfileDropdown, setShowProfileDropdown] =
    useState<boolean>(false);

  const profileDropdownWrapperRef = useRef(null);
  const sideMenuRef = useRef(null);
  useOutsideAlerter(profileDropdownWrapperRef, () =>
    setShowProfileDropdown(false)
  );
  useOutsideAlerter(sideMenuRef, () => setSideMenuIsOpen(false));

  const canUseModelEditor = useHasRole(
    undefined,
    PermissionGroupSupplierId.MODEL_EDITOR
  );
  return (
    <Container>
      <RowSpaceBetween>
        <LeftSide>
          <Icon
            svg={LogyxLogoSVG}
            onClick={() =>
              navigateWithQuery(
                getLogoNavigationDestinationBasedOnAppMode(appMode)
              )
            }
            fill={COLORS.BLACK}
            size={123}
            noRipple
          />
        </LeftSide>
        <RightSide>
          {!isTablet && (
            <>
              {appMode === AppMode.COMPANY ? (
                <NavigationLabel
                  onClick={() => {
                    navigateWithQuery(RoutesConfig.Models.fullPath);
                  }}
                  isSelected={
                    location.pathname.includes(RoutesConfig.Models.fullPath) ||
                    location.pathname.includes(
                      RoutesConfig.Configure.fullPath
                    ) ||
                    location.pathname.includes(RoutesConfig.AllModels.fullPath)
                  }
                >
                  {t('Models')}
                </NavigationLabel>
              ) : null}
              {appMode === AppMode.SUPPLIER ? (
                <NavigationLabel
                  onClick={() => {
                    navigateWithQuery(RoutesConfig.MyModels.fullPath);
                  }}
                  isSelected={
                    location.pathname.includes(
                      RoutesConfig.MyModels.fullPath
                    ) ||
                    location.pathname.includes(
                      RoutesConfig.SupplierModelCompanies.path
                    )
                  }
                >
                  {t('My models')}
                </NavigationLabel>
              ) : null}
              {canUseModelEditor &&
              [AppMode.SUPPLIER, AppMode.DEALER_PANEL].includes(appMode) ? (
                <NavigationLabel
                  onClick={() => {
                    navigateWithQuery(RoutesConfig.CustomModels.fullPath);
                  }}
                  isSelected={location.pathname.includes(
                    RoutesConfig.CustomModels.fullPath
                  )}
                >
                  {t('Custom models')}
                </NavigationLabel>
              ) : null}
              {appMode === AppMode.SUPPLIER ? (
                <NavigationLabel
                  onClick={() => {
                    navigateWithQuery(RoutesConfig.SupplierCompanies.fullPath);
                  }}
                  isSelected={location.pathname.includes(
                    RoutesConfig.SupplierCompanies.fullPath
                  )}
                >
                  {t('Companies')}
                </NavigationLabel>
              ) : null}
              {[AppMode.SUPPLIER, AppMode.COMPANY].includes(appMode) ? (
                <NavigationLabel
                  onClick={() => {
                    navigateWithQuery(RoutesConfig.Users.fullPath);
                  }}
                  isSelected={location.pathname.includes(
                    RoutesConfig.Users.fullPath
                  )}
                >
                  {t('Users')}
                </NavigationLabel>
              ) : null}
              {appMode === AppMode.DEALER_PANEL ? (
                <NavigationLabel
                  onClick={() => {
                    navigateWithQuery(
                      RoutesConfig.DealerPanelSuppliers.fullPath
                    );
                  }}
                  isSelected={
                    location.pathname.includes(
                      RoutesConfig.DealerPanelSuppliers.fullPath
                    ) ||
                    location.pathname.includes(
                      RoutesConfig.DealerPanelModels.fullPath
                    )
                  }
                >
                  {t('Suppliers')}
                </NavigationLabel>
              ) : null}
            </>
          )}
          {!isTablet && (
            <>
              {[AppMode.BLUEPRINT, AppMode.DEALER_PANEL].includes(
                appMode
              ) ? null : (
                <SwitchAppMode />
              )}
            </>
          )}
          {isTablet && appMode === AppMode.DEALER_PANEL && (
            <SwitchersWrapper>
              <ToggleDarkMode />
            </SwitchersWrapper>
          )}
          {isSmallPhone && appMode !== AppMode.BLUEPRINT ? null : (
            <SwitchersWrapper>
              <LanguageSwitcher />
            </SwitchersWrapper>
          )}
          {[AppMode.COMPANY, AppMode.SUPPLIER].includes(appMode) ? (
            <Tooltip
              content={
                appMode === AppMode.SUPPLIER
                  ? t('Shopping cart can only be used while in company mode')
                  : null
              }
            >
              <Icon
                disabled={appMode === AppMode.SUPPLIER}
                svg={ShoppingCart}
                onClick={() => {
                  navigateWithQuery(RoutesConfig.ShoppingCart.fullPath);
                }}
                color={COLORS.GREEN}
                size={30}
              />
            </Tooltip>
          ) : null}
          {[AppMode.COMPANY, AppMode.SUPPLIER].includes(appMode) &&
          !isSmallPhone ? (
            <Icon
              svg={SettingsIcon}
              onClick={() => {
                navigateWithQuery(RoutesConfig.Settings.fullPath);
              }}
              color={COLORS.GREEN}
              size={30}
              marginLeft="10rem"
            />
          ) : null}
          {userId ? (
            <div ref={profileDropdownWrapperRef}>
              <Icon
                svg={User}
                onClick={() => setShowProfileDropdown(!showProfileDropdown)}
                size={30}
                marginLeft="10rem"
                color={COLORS.GREEN}
              />
              {showProfileDropdown && (
                <ProfileDropdown
                  setShowProfileDropdown={setShowProfileDropdown}
                />
              )}
            </div>
          ) : null}
          {isTablet && appMode !== AppMode.BLUEPRINT && (
            <Icon
              svg={List}
              color={COLORS.GREEN}
              onClick={() => setSideMenuIsOpen((prev) => !prev)}
              marginLeft="10rem"
              size={30}
            />
          )}
        </RightSide>
        <EnvironmentBanner />
      </RowSpaceBetween>

      <SubHeader
        isExpanded={
          appMode === AppMode.BLUEPRINT
            ? true
            : location.pathname.includes(RoutesConfig.Configure.fullPath) &&
              !!modelId
        }
      />
      {isTablet && appMode !== AppMode.BLUEPRINT ? (
        <SideMenu
          ref={sideMenuRef}
          active={sideMenuIsOpen}
          setSideMenuIsOpen={setSideMenuIsOpen}
        />
      ) : null}
    </Container>
  );
};

export default Header;
