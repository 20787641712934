import { COLORS } from 'assets/styled';
import styled from 'styled-components';
import { css } from 'styled-components';
import { CSSProperties } from 'react';

interface StyledProps {
  darkMode: boolean;
  wrapperStyles: CSSProperties;
  labelStyles: CSSProperties;
}

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(props: StyledProps) =>
    props.wrapperStyles &&
    css`
      ${props.wrapperStyles}
    `}
`;

export const ClickableContainer = styled.div`
  cursor: pointer;
  align-items: center;
  /* display: flex; */
  flex-direction: row;
  align-items: center;
`;

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  accent-color: ${COLORS.GREEN};
  cursor: pointer;
  ${(props: StyledProps) =>
    props.darkMode
      ? css`
          color-scheme: dark;
        `
      : css`
          color-scheme: light;
        `}
`;

export const CheckboxSpan = styled.span`
  margin-left: 5rem;
  font-weight: 400;
  font-size: 16rem;
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.labelStyles &&
    css`
      ${props.labelStyles}
    `}
`;
