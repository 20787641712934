import { useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { queryModel } from 'services/ModelService';
import {
  setExtraPriceQuestionData,
  setImages,
  setIsUsingSupplierSystemDiscounts,
  setLang,
  setModel,
  setPricing,
  setQueryPayload,
  setQuestions,
} from 'store/Model/actions/model';
import {
  GET_EDIT_CONFIGURATION_ATTEMPT,
  GET_EDIT_CONFIGURATION_ERROR,
  GET_EDIT_CONFIGURATION_SUCCESS,
  QUERY_ATTEMPT,
  QUERY_ERROR,
  QUERY_RESET,
  QUERY_SUCCESS,
} from 'store/constants';
import { IRootReducerState } from 'store/store';
import {
  useFormatFormValuesIntoQueryPayload,
  useModelIdChange,
  useIsEditMode,
  useValuesDidUpdate,
  useGetTaxRate,
  useGetBuilderEditVersionId,
} from './hooks';
import { getEditConfiguration } from 'services/ExternalService';
import {
  useListenForAddToCartTrigger,
  useListenForEditCartItemTrigger,
} from 'pages/ConfigurePage/ConfigureForm/hooks';
import { IQuestionDifferences, checkQuestionDifferences } from './validation';

import { setEditConfigurationValidationWarnings } from 'store/Common/actions/common';
import { toast } from 'react-toastify';
import i18n, { FALLBACK_LOCALE } from 'providers/i18n/i18n';
import {
  IExtraPriceQuestionData,
  ITestCompanyModelConfiguration,
} from 'types/Configuration.types';
import { AppMode } from 'store/Common/reducers/common';
import { setSelectedExtraPriceQuestionOption } from 'store/SubmitConfiguration/actions/submitConfiguration';
import {
  handleExtraPriceQuestionAfterObtainingEditConfiguration,
  handleExtraPriceQuestionAfterSuccessfulQuery,
  mergeDifferences,
} from './helpers';

interface IQueryProviderProps {
  children: React.ReactNode;
}

// QueryProvider is placed inside the form context, wraps all FormItems and sends a query request to logix with the current form values if any value changes
const QueryProvider = ({ children }: IQueryProviderProps) => {
  const isEditMode = useIsEditMode(); // Edit with webhook
  const [hasObtainedEditConfiguration, setHasObtainedEditConfiguration] =
    useState<boolean>(false); // Edit with webhook

  const dispatch = useDispatch();
  const { values } = useFormikContext();
  const { hasModelIdChanged, currentModelId } = useModelIdChange();

  const editVersionId: number | undefined = useGetBuilderEditVersionId();
  const taxRate: number | undefined = useGetTaxRate();
  const testConfiguration: ITestCompanyModelConfiguration | null = useSelector(
    (state: IRootReducerState) =>
      state.submitConfigurationInfo.testCompanyModelConfiguration
  );
  const didValuesChange = useValuesDidUpdate(
    values,
    currentModelId,
    testConfiguration,
    taxRate
  );
  const questionInitialKeysToExcludeInQueryRef = useRef<string[]>([]);
  const queryPayload = useFormatFormValuesIntoQueryPayload(
    values as object,
    questionInitialKeysToExcludeInQueryRef
  );

  const queryRequestStatus = useSelector(
    (state: IRootReducerState) => state.modelInfo.queryStatus
  );

  useEffect(() => {
    if (queryRequestStatus.success || queryRequestStatus.error) {
      dispatch({ type: QUERY_RESET });
    }
  }, [queryRequestStatus]);

  const { base64Metadata, selectedExtraPriceQuestionOption } = useSelector(
    (state: IRootReducerState) => state.submitConfigurationInfo
  );
  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );

  // In ConfigureForm, new values are set if the query response outputs and the current formik values missmatch
  useEffect(() => {
    (async () => {
      if ((isEditMode && hasObtainedEditConfiguration) || !isEditMode) {
        if (!queryRequestStatus.attempt && currentModelId) {
          if (
            didValuesChange ||
            questionInitialKeysToExcludeInQueryRef.current.length // Required in order to trigger the query request after fetching edit data
          ) {
            try {
              dispatch({ type: QUERY_ATTEMPT });
              const lang = i18n.resolvedLanguage || FALLBACK_LOCALE;
              const queryModelPromise = queryModel(
                currentModelId,
                queryPayload,
                lang,
                taxRate,
                appMode === AppMode.DEALER_PANEL ? testConfiguration : null,
                editVersionId
              );
              const [queryData] = await Promise.all([queryModelPromise]);
              if (questionInitialKeysToExcludeInQueryRef.current.length) {
                questionInitialKeysToExcludeInQueryRef.current = [];
              }
              dispatch({ type: QUERY_SUCCESS });
              dispatch(
                setModel({
                  general: queryData.general,
                  id: queryData.id,
                  version_number: queryData.version_number,
                  round_pricing: queryData.round_pricing,
                })
              );
              dispatch(setQuestions(queryData.questions));
              dispatch(setQueryPayload(queryPayload.questions));
              dispatch(setLang(lang));
              dispatch(setImages(queryData.images));
              dispatch(setPricing(queryData.pricing));
              handleExtraPriceQuestionAfterSuccessfulQuery(
                dispatch,
                selectedExtraPriceQuestionOption,
                queryData?.extra_price_question_data || null
              );
              dispatch(
                setIsUsingSupplierSystemDiscounts(
                  queryData.is_using_supplier_system_discounts
                )
              );
            } catch (error: any) {
              if (error?.response?.data?.message) {
                // Display error message if model has been disabled for this company
                toast.error(error.response.data.message, {
                  toastId: 'QUERY_ERROR',
                });
              }
              dispatch({ type: QUERY_ERROR });
              dispatch(setQuestions({}));
              dispatch(setQueryPayload({}));
              dispatch(setImages({}));
              dispatch(setPricing({}));
              dispatch(setExtraPriceQuestionData(null));
              dispatch(setSelectedExtraPriceQuestionOption(null));
              dispatch(setIsUsingSupplierSystemDiscounts(false));
            }
          }
        }
      }
    })();
  }, [hasObtainedEditConfiguration, didValuesChange, hasModelIdChanged]);

  const { intentUUID } = useSelector(
    (state: IRootReducerState) => state.submitConfigurationInfo
  );
  useEffect(() => {
    (async () => {
      if (isEditMode && intentUUID && !hasObtainedEditConfiguration) {
        try {
          dispatch({ type: GET_EDIT_CONFIGURATION_ATTEMPT });
          const getEditConfigurationPromise =
            getEditConfiguration(base64Metadata);
          const [editConfigurationData] = await Promise.all([
            getEditConfigurationPromise,
          ]);
          dispatch({ type: GET_EDIT_CONFIGURATION_SUCCESS });

          // After obtaining existing configuration, fetch the newest questions for the model,
          // and check for differences in questions and options
          const {
            differences,
            extraPriceQuestionData,
          }: {
            differences: IQuestionDifferences;
            extraPriceQuestionData: IExtraPriceQuestionData | null;
          } = await checkQuestionDifferences(editConfigurationData);

          const extraPriceQuestionDifferences: IQuestionDifferences =
            handleExtraPriceQuestionAfterObtainingEditConfiguration(
              dispatch,
              extraPriceQuestionData,
              editConfigurationData
            );
          const mergedDifferences = mergeDifferences(
            differences,
            extraPriceQuestionDifferences
          );

          if (mergedDifferences) {
            dispatch(setEditConfigurationValidationWarnings(mergedDifferences));
            const excludedQuestionKeys = [
              ...differences.missingQuestionsKeys,
              ...differences.extraQuestionsKeys,
              ...differences.matchingQuestionsWithInvalidOutputsKeys,
            ];
            questionInitialKeysToExcludeInQueryRef.current =
              excludedQuestionKeys;
          }

          dispatch(setQuestions(editConfigurationData.questions));
          setHasObtainedEditConfiguration(true);
        } catch (error) {
          dispatch({ type: GET_EDIT_CONFIGURATION_ERROR });
          dispatch(setQuestions({}));
        }
      }
    })();
  }, [isEditMode, intentUUID]);

  useListenForAddToCartTrigger(queryPayload, dispatch);
  useListenForEditCartItemTrigger(queryPayload, dispatch);

  return <>{children}</>;
};

export default QueryProvider;
